<template>
    <section id="customers" class="py-16 bg-white">
        <div class="container mx-auto px-4">
            <h2 class="text-3xl font-bold text-center mb-12 text-brand">Onze klanten</h2>
            <div class="flex flex-wrap justify-center gap-8">
                <!-- Customer logos -->
                <img v-for="(customer, index) in customers" :key="index" :src="customer.logo" :alt="customer.name"
                    class="w-24 h-24 object-contain transition-all duration-200" :class="{
                        'grayscale opacity-60 pointer-events-none': !customer.description && !customer.url,
                        'animate-pulse-scale cursor-pointer': customer.description || customer.url,
                    }" @click="handleCustomerClick(customer)" />
            </div>
        </div>

        <!-- Fullscreen Modal -->
        <transition name="fade">
            <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <!-- Modal Content -->
                <div class="relative w-full h-full bg-brand text-white overflow-y-auto p-6">
                    <!-- Close Button -->
                    <button class="absolute top-4 right-4 text-white hover:text-gray-900 text-3xl font-bold"
                        @click="closeModal">
                        ✕
                    </button>

                    <!-- Modal Body -->
                    <div class="max-w-3xl mx-auto">
                        <h2 class="text-2xl font-bold mb-4">{{ selectedCustomer.name }}</h2>
                        <p class="mb-4">
                            {{ selectedCustomer.description || "Geen beschrijving beschikbaar." }}
                        </p>
                        <img v-if="selectedCustomer.caseImage" :src="selectedCustomer.caseImage"
                            :alt="`Case for ${selectedCustomer.name}`" class="w-full h-auto mb-4 object-cover" />
                    </div>
                </div>
            </div>
        </transition>
    </section>
</template>

<script>
export default {
    props: {
        customers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showModal: false,
            selectedCustomer: {},
        };
    },
    methods: {
        handleCustomerClick(customer) {
            if (customer.url) {
                // Navigate to the URL in a new tab
                window.open(customer.url, "_blank");
            } else if (customer.description) {
                // Open modal if description exists
                this.openModal(customer);
            }
        },
        openModal(customer) {
            // Lock scrolling on the background
            document.body.style.overflow = "hidden";

            // Enable modal and set the customer data
            this.selectedCustomer = customer;
            this.showModal = true;

            // Add a keydown listener for Escape key
            document.addEventListener("keydown", this.handleKeydown);
        },
        closeModal() {
            // Unlock scrolling on the background
            document.body.style.overflow = "";

            // Disable modal and clear customer data
            this.showModal = false;
            this.selectedCustomer = {};

            // Remove the keydown listener
            document.removeEventListener("keydown", this.handleKeydown);
        },
        handleKeydown(event) {
            if (event.key === "Escape") {
                this.closeModal();
            }
        },
    },
};
</script>

<style scoped>
/* Fade animation for modal */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* Keyframe for pulsing grow-shrink effect */
@keyframes pulse-scale {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

/* Animation class for logos with descriptions or URLs */
.animate-pulse-scale {
    animation: pulse-scale 2s infinite ease-in-out;
}
</style>
