import { createApp } from 'vue';
import App from './App.vue';
import './assets/main.css';
import scrollAnimation from './directives/scroll.js';

const app = createApp(App);

app.directive('scroll', scrollAnimation);

app.mount('#app');
