<template>
    <section class="h-screen flex flex-col justify-center bg-brand text-white relative">
        <!-- Top Navigation -->
        <div class="absolute top-0 left-0 right-0 flex justify-between items-center p-4">
            <!-- Small Logo -->
            <img src="/img/pu-logo2.svg" alt="Small Logo" class="w-10 h-auto opacity-80" />

            <!-- Navigation Links -->
            <nav class="flex space-x-6 z-50">
                <a href="#about"
                    class="text-white opacity-80 text-sm uppercase border-b-2 border-transparent hover:border-white transition duration-300">Over</a>
                <a href="#services"
                    class="text-white opacity-80 text-sm uppercase border-b-2 border-transparent hover:border-white transition duration-300">Diensten</a>
                <a href="#customers"
                    class="text-white opacity-80 text-sm uppercase border-b-2 border-transparent hover:border-white transition duration-300">Klanten</a>
                <a href="#contact"
                    class="text-white opacity-80 text-sm uppercase border-b-2 border-transparent hover:border-white transition duration-300">Contact</a>
            </nav>
        </div>

        <!-- Hero Content -->
        <div class="relative text-center z-10 mx-auto" style="max-width: 75vw">
            <h1 class="text-5xl font-bold mb-4">
                Python United is een klein hecht team van zeer ervaren software
                engineers.
            </h1>
            <p class="text-xl mt-12 text-center relative inline-flex items-center w-3/4 opacity-80">
                Leer ons beter kennen door vrijblijvend langs te komen voor een goed gesprek.
                <img src="/img/coffee.svg" alt="Small Logo" class="w-16 h-auto ml-2 svg-icon" />
            </p>
        </div>

        <!-- Watermarked Logo -->
        <img src="/img/pu-logo2.svg" alt="Watermarked Logo" class="watermarked-logo" />
    </section>
</template>

<script>
export default {
    mounted() {
        // Add smooth scroll behavior to anchor links
        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                const targetId = link.getAttribute('href');
                const target = document.querySelector(targetId);
                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' });
                }
            });
        });
    },
};
</script>

<style scoped>
/* Scoped styles to ensure consistent rendering */
section {
    position: relative;
}

.watermarked-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75vw;
    /* Always 75% of the viewport width */
    filter: brightness(1.2);
    opacity: 0.1;
    /* animation: flip-logo 10s infinite; */
    animation: shrink-grow 20s infinite;
}

.svg-icon {
    fill: white;
    filter: brightness(1);
}

@keyframes flip-logo {
    0% {
        transform: translate(-50%, -50%) scale(1) rotateY(0deg);
    }

    25% {
        transform: translate(-50%, -50%) scale(1.1) rotateY(90deg);
    }

    50% {
        transform: translate(-50%, -50%) scale(1) rotateY(180deg);
    }

    75% {
        transform: translate(-50%, -50%) scale(1.1) rotateY(270deg);
    }

    100% {
        transform: translate(-50%, -50%) scale(1) rotateY(360deg);
    }
}

@keyframes shrink-grow {

    0%,
    100% {
        transform: translate(-50%, -50%) scale(1);
        /* Normal size */
    }

    50% {
        transform: translate(-50%, -50%) scale(1.3);
        /* Slightly larger */
    }
}

img[alt="Watermarked Logo"] {
    /* Ensure no max/min limits to maintain proportionality */
    max-width: none;
    min-width: 0;
}
</style>
