<template>
    <section id="services" v-scroll class="py-16 bg-green-50 text-gray-800">
        <div class="container mx-auto px-4">
            <h2 class="text-3xl font-bold text-center mb-12 text-brand">Onze Diensten</h2>
            <div class="grid md:grid-cols-3 gap-8">
                <div v-for="(service, index) in services" :key="index"
                    class="relative p-6 bg-white rounded-lg shadow-md overflow-hidden">
                    <!-- Watermark Background -->
                    <div v-if="service.watermark"
                        class="absolute inset-0 bg-no-repeat bg-contain opacity-10 pointer-events-none transform rotate-10deg"
                        :style="{ backgroundImage: `url(${service.watermark})` }"></div>
                    <!-- Card Content -->
                    <div class="relative">
                        <h3 class="text-2xl text-brand font-semibold mb-4">{{ service.title }}</h3>
                        <p class="mb-12">{{ service.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        services: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.bg-no-repeat {
    background-position: center;
}

.bg-contain {
    background-size: cover;
}

.opacity-10 {
    z-index: 0;
}

.transform {
    z-index: 0;
}

.rotate-10deg {
    transform: rotate(-10deg);
    /* Adjust rotation angle */
}

.relative>* {
    z-index: 10;
    /* Ensure content appears above the watermark */
}

.pointer-events-none {
    pointer-events: none;
    /* Prevent the watermark from interfering with user interactions */
}
</style>
