<template>
    <section id="about" v-scroll class="py-16 bg-white">
        <div class="container mx-auto px-4">
            <h2 class="text-3xl font-bold text-center mb-12 text-brand">Over Python United</h2>
            <div class="flex flex-wrap justify-left gap-8">
                <p>
                    Python United is bewust een klein, hecht team van tien software­specialisten. Deze compacte aanpak
                    houdt ons efficiënt, daadkrachtig én zorgt voor een fijne samenwerking met onze klanten. We werken
                    al meer dan tien jaar samen, wat een bewezen garantie is voor continuïteit.
                </p>
                <p>
                    We zijn gespecialiseerd in het ontwikkelen en beheren van Python ­softwareapplicaties en
                    API-koppelingen. Daarbij maken we veel gebruik van Open Source oplossingen en bouwen we
                    maatwerkapplicaties waarin technologie en data samenkomen. Naast de backend nemen we steeds vaker de
                    verantwoordelijkheid voor de interactieve werking van de frontend.
                </p>
                <p>
                    Onze kracht? We realiseren de ambities en lossen de uitdagingen van onze klanten op een
                    technologische, vooruitstrevende manier op – en altijd binnen de afgesproken tijd.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
</script>

<style scoped>
/* Add CustomersSection-specific styles if needed */
</style>
