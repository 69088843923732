<template>
  <div>
    <HeroSection />
    <AboutSection />
    <ServicesSection :services="services" />
    <CustomersSection :customers="customers" />
    <TimelineSection :events="timelineEvents" style="display: none;" />
    <FooterSection />
  </div>
</template>

<script>
import AboutSection from './components/AboutSection.vue';
import CustomersSection from './components/CustomersSection.vue';
import FooterSection from './components/FooterSection.vue';
import HeroSection from './components/HeroSection.vue';
import ServicesSection from './components/ServicesSection.vue';
import TimelineSection from './components/TimelineSection.vue';

export default {
  components: {
    HeroSection,
    AboutSection,
    ServicesSection,
    CustomersSection,
    FooterSection,
    TimelineSection,
  },
  data() {
    return {
      services: [
        { title: "Web Development", description: "We realiseren moderne, schaalbare websites, apps en API's, bijvoorbeeld met Django, Wagtail, Ninja, TypeScript, Vue en Nuxt." },
        { title: "UI/UX Design", description: "In samenwerking met onze partners leveren we gebruiksvriendelijke ontwerpen en strak uitgevoerde implementaties." },
        { title: "Consulting", description: "Wij bieden deskundige, no-nonsense projectbegeleiding. Met korte lijnen zorgen we voor een vlotte en transparante samenwerking." },
      ],
      customers: [
        { name: "Noorderlink", logo: "/img/noorderlink.png", xurl: "https://noorderlink.nl", description: "Noorderlink is een Nederlands bedrijf dat zich specialiseert in het ontwikkelen van websites en webapplicaties. Het bedrijf biedt een breed scala aan diensten, waaronder ontwikkeling van e-commerceoplossingen, contentmanagementsystemen en custom webapplicaties." },
        { name: "Milieudefensie", logo: "/img/milieudefensie.png", url: "https://milieudefensie.nl" },
        { name: "Leen een Fries", logo: "/img/leeneenfries.png", url: "https://leeneenfries.nl/" },
        { name: "Altenburg & Wymenga", logo: "/img/altenburg-wymenga.png", url: "https://www.altwym.nl/en/" },
        { name: "Vos Interieur", logo: "/img/vos-interieur.png", url: "https://vos.design/" },
        { name: "Gemeente Groningen", logo: "/img/gemeente-groningen.png" },
        { name: "Provincie Groningen", logo: "/img/provincie-groningen.png" },
        { name: "Wetterskip Fryslân", logo: "/img/wetterskip.png", url: "https://wetterskipfryslan.nl" },
        { name: "NHL Stenden", logo: "/img/nhl-stenden.png" },
        { name: "We Helpen", logo: "/img/we-helpen.png" },
        { name: "Srprsme", logo: "/img/srprsme.png", url: "https://srprs.me/nl" },
        { name: "Holland Sensor", logo: "/img/holland-sensor.png", url: "https://hollandsensor.com" },
        { name: "BCA", logo: "/img/bca.png", url: "https://www.bca.com/" },
        { name: "ZVHG", logo: "/img/zvhg.png", url: "https://www.zorgenveiligheidshuizen.nl/locaties/groningen/" },
        { name: "Familienet", logo: "/img/familienet.jpeg", url: "https://www.familienet.nl/" },
        { name: "Madlogic", logo: "/img/madlogic.png", url: "https://madlogic.dev/" },
        { name: "Platform Drentse Musea", logo: "/img/pdm.png", url: "https://platformdrentsemusea.nl/" },
        { name: "Eurosonic Noorderslag", logo: "/img/esns.jpeg", url: "https://esns.nl/nl/" },
        { name: "Unie van Waterschappen", logo: "/img/uvw.png", url: "https://www.uwv.nl/nl" },
        { name: "Voys", logo: "/img/voys.png", url: "https://www.voys.nl/" },
        { name: "Wetlands International", logo: "/img/wetlands.jpeg", url: "https://www.wetlands.org/" },
        { name: "Studio Reactor", logo: "/img/studio-reactor.jpeg", url: "https://studioreactor.com/" },
        { name: "Wyldebeast & Wunderliebe", logo: "/img/w20e.png", url: "https://wyldebeast-wunderliebe.com/" },
        { name: "Turftorr", logo: "/img/turftorr.png", url: "https://turftorr.nl/" },
        { name: "Zonnepanelen Delen", logo: "/img/zonnepanelen-delen.png", url: "https://www.zonnepanelendelen.nl/" },
      ],
      timelineEvents: [
        { title: "Event 1", description: "Description for event 1", date: "2025-01-01" },
        { title: "Event 2", description: "Description for event 2", date: "2025-02-01" },
        { title: "Event 3", description: "Description for event 3", date: "2025-03-01" },
      ],
    };
  },
};
</script>
