<template>
    <section id="timeline" class="py-16 bg-gray-100">
        <div class="container mx-auto px-4">
            <h2 class="text-3xl font-bold text-center mb-12 text-brand">Timeline</h2>
            <div class="timeline">
                <div v-for="(event, index) in events" :key="index" class="timeline-item" v-scroll>
                    <div class="timeline-content">
                        <h3 class="text-xl font-semibold">{{ event.title }}</h3>
                        <p>{{ event.description }}</p>
                        <span class="text-sm text-gray-500">{{ event.date }}</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        events: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.timeline {
    position: relative;
    padding: 1rem 0;
    list-style: none;
}

.timeline-item {
    position: relative;
    margin-bottom: 2rem;
    padding-left: 2rem;
}

.timeline-item::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1rem;
    height: 1rem;
    background-color: #43B78B;
    border-radius: 50%;
}

.timeline-content {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
