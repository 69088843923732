export default {
  beforeMount(el) {
    el.classList.add('opacity-0', 'translate-y-8', 'transition', 'duration-700'); // Initial state
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          el.classList.add('opacity-100', 'translate-y-0');
          el.classList.remove('opacity-0', 'translate-y-8');
          observer.unobserve(el); // Stop observing after animation
        }
      },
      { threshold: 0.1 }
    );
    observer.observe(el);
  },
};
