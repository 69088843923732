<template>
    <footer id="contact" class="bg-gray-800 text-white pt-6 pb-24">
        <div class="container mx-auto text-center">
            <p class="mb-3">&copy; {{ currentYear }} Python United U.A. Alle rechten voorbehouden.</p>
            <div class="flex flex-col items-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 justify-center">
                <!-- Phone Icon -->
                <div class="flex items-center space-x-2">
                    <PhoneIcon class="w-6 h-6 text-brand" />
                    <span><a href="tel:+31854012977">085 4012 977</a></span>
                </div>
                <!-- Email Icon -->
                <div class="flex items-center space-x-2">
                    <EnvelopeIcon class="w-6 h-6 text-brand" />
                    <span><a href="mailto:info@pythonunited.com">info@pythonunited.com</a></span>
                </div>
                <!-- X (Twitter) Icon -->
                <div class="flex items-center space-x-2">
                    <XMarkIcon class="w-6 h-6 text-brand" />
                    <span>@PythonUnited</span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { EnvelopeIcon, PhoneIcon, XMarkIcon } from '@heroicons/vue/24/solid';

export default {
    components: {
        PhoneIcon,
        EnvelopeIcon,
        XMarkIcon,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>

<style scoped>
/* Add Footer-specific styles if needed */
</style>
